@import 'Styles';

.SplashVideo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  .filter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($theme_bg, 0.7);
    z-index: 1;
  }

  iframe {
    position: absolute;
    transform: translate(-22%, -22%);
  }
}
