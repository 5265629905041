@import 'Styles';

#Promo {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin: 0 20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buttonPanel {
    width: 128px;
    height: 64px;
    display: flex;
  }

  button {
    flex: 1;
    margin-top: 32px;
    background: $theme_fg;
    border: 4px solid $theme_bg;
    color: $theme_bg;
    border-radius: 5px;

    &:hover {
      border-color: $theme_fg;
    }

    &:disabled {
      display: none;
    }
  }

  ul {
    columns: 3;

    @media (max-width: 1600px) {
      columns: 2;
    }
    @media (max-width: 800px) {
      columns: 1;
    }

    li {
      margin: 12px 12px 0 0;
    }
  }

  .roster {
    display: flex;
    flex-direction: column;

    .biopic {
      background-position: center;
      background-repeat: no-repeat;
      border: 2px solid $theme_fg;
      border-radius: 5px;
    }

    > * {
      display: flex;
      flex-direction: row;
      width: 50vw;

      > * {
        padding: 0 18px;
      }

      &:nth-child(even) {
        flex: 1;
        flex-direction: row-reverse;
      }
    }
  }
}
