@import 'Styles';

#Splash {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 60%;
  }

  .backgroundPromo {
    position: fixed;
    z-index: -1;
    pointer-events: none;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tagline {
    margin: 8px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .panelHeader {
    margin: 8px 0 16px 0;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }

  .promo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $theme_bg;
    padding: 16px;
  }

  .video {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 0 32px 32px;
  }

  .eventsFeedPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 340px;
    min-height: 340px;
    padding: 0 32px 32px;
  }
}
