@import 'Styles';

html,
body {
  overflow: hidden;

  &,
  #App {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    color: $theme_fg;
    font-family: $theme_font;
    text-shadow: 2px 2px 0 $theme_bg, 2px -2px 0 $theme_bg, -2px 2px 0 $theme_bg,
      -2px -2px 0 $theme_bg, 2px 0px 0 $theme_bg, 0px 2px 0 $theme_bg,
      -2px 0px 0 $theme_bg, 0px -2px 0 $theme_bg;
  }
}

a {
  font-weight: bold;
  text-decoration: none;
  color: $theme_fg;

  &:hover {
    color: blue;
  }
}

#App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}

* {
  box-sizing: border-box;
}

button:not(:disabled) {
  cursor: pointer;
}
