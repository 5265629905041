@import 'Styles';

.SocialLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    min-width: 32px;
    margin: 8px;
    fill: rgba($theme_fg, 0.8);

    &:hover {
      svg {
        fill: $theme_fg;
      }
    }
  }
}
